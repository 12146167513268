<template>
  <card-component :title="this.creating ? 'Nieuwe Vlan': 'Wijzig Vlan'"
                  :icon="this.creating ? 'plus-circle' : 'pencil'"
                  :is-scrollable="true">


    <form @submit.prevent="submit">
      <b-field label="Naam en Vlan" horizontal>
        <b-field>
          <b-input v-model="vlan.name" placeholder="Vlan naam" name="name" required/>
        </b-field>

        <b-field>
          <b-input v-bind:disabled="!this.creating" v-model="vlan.code" placeholder="Vlan 2024" name="vlan"
                   required/>
        </b-field>

      </b-field>

      <b-field label="Adres" horizontal>
        <b-field>
          <b-input v-model="vlan.description" placeholder="Beschrijving" name="address" required/>
        </b-field>
      </b-field>
      <hr>
      <b-field label="Beschikbare gebouwen" horizontal>
        <div class="media-list">
          <div class="media" v-for="building in vlan.buildings" :key="building.code">
            <div class="media-left">
              <b-icon icon="office-building-outline">
              </b-icon>
              <b><p>{{ building.code }}</p></b>
            </div>
            <div class="media-content">
              {{ building.name }}
              <br>
              <small>
                {{ building.address }},
                {{ building.city }}
              </small>
            </div>
          </div>
        </div>
      </b-field>

      <hr>

      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button type="is-primary is-outlined" @click="this.$parent.close">Annuleer</b-button>
          </div>
          <div class="control">
            <b-button disabled native-type="submit" type="is-primary">{{
                this.creating ? "Voeg toe" : "Bewaar"
              }}
            </b-button>
          </div>
        </b-field>

      </b-field>

    </form>

  </card-component>
</template>

<script>

import CardComponent from "@/components/CardComponent";

export default {
  name: "VlanFormComponent",
  components: {CardComponent},
  props: {
    code: {
      default: null
    },
  },
  data() {
    return {
      vlan: {},
    }
  },

  computed: {
    creating() {
      return this.code === null
    }

  },

  mounted() {
    if (this.creating) {
      this.vlan = {
        name: "",
        address: "",
        description: "",

      }
    } else {
      // Fetch vlan data
      this.$store.dispatch('fetchVlanDetails', this.code)
          .then(response => {
            this.vlan = JSON.parse(JSON.stringify(response.data))
          })
    }
  },

  methods: {

    submit() {
      // Add new vlan
      if (this.creating) {
        // this.$store.dispatch('createBuilding', this.vlan)
        //     .then(response => {
        //         console.log(response)
        //         this.$parent.close()
        //         this.$buefy.toast.open({
        //             message: 'Building created',
        //             type: 'is-success'
        //         })
        //         this.$emit("update")
        //
        //     }).catch(err => {
        //     this.$buefy.toast.open({
        //         message: err,
        //         type: 'is-danger'
        //     })
        //     })
      }
      // Update existing vlan
      else {
        // this.$store.dispatch('updateBuilding', {code: this.code, data: this.vlan})
        //     .then(response => {
        //       console.log(response)
        //         this.$parent.close()
        //         this.$buefy.toast.open({
        //             message: 'Building updated',
        //             type: 'is-success'
        //         })
        //
        //     }).catch(err => {
        //     this.$buefy.toast.open({
        //         message: err,
        //         type: 'is-danger'
        //     })
        // })
      }

    },

  }

}
</script>

<style scoped lang="scss">


</style>