<template>
  <div class="container px-5" style="max-width: 1000px">

    <card-component :has-title-slot="true" class=" has-table">
      <header slot="title" class="level is-mobile">
        <div class="level-left">
          <a class="level-item" @click="$router.go(-1)">
            <b-icon type="is-grey-darker" icon="arrow-left" class="ml-4"></b-icon>
          </a>
          <p class="level-item title is-5 pl-0">
            VLAN overzicht
          </p>
        </div>
      </header>

      <b-table :data="vlans"
               paginated
      >

        <b-table-column field="code" label="VLAN" width="60" sortable v-slot="props">
          <b-tag type="is-info"><b>{{ props.row.code }}</b></b-tag>
        </b-table-column>

        <b-table-column field="name" label="Naam" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="city" label="Beschrijving" v-slot="props">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="city" label="Aantal beschikbare gebouwen" v-slot="props">
          <b-tag rounded>{{ props.row.buildings_count }}</b-tag>
        </b-table-column>

        <b-table-column field="edit" v-slot="props">
          <div class="buttons is-right">
            <b-button icon-left="eye" size="is-small" type="is-primary"
                      @click="formModal(props.row.id)"></b-button>

          </div>
        </b-table-column>

      </b-table>
    </card-component>
  </div>
</template>

<script>
import VlanFormComponent from "@/components/vlans/VlanFormComponent";
import CardComponent from "@/components/CardComponent.vue";

export default {
  name: "VlansOverview",
  components: {
    CardComponent

  },
  data() {
    return {
      vlans: [],
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchAllVlans").then(response => {
        this.vlans = response.data
      })
      this.$store.dispatch('setTitle', {
        back: true,
        stack: [
          {
            message: "Vlans",
          }
        ]
      })
    },

    formModal(id) {
      this.$buefy.modal.open({
        parent: this,
        component: VlanFormComponent,
        props: {
          code: id
        },
        hasModalCard: true,
        trapFocus: true
      })
    },

  }
}
</script>

<style scoped>

.box {
  transition: box-shadow .3s;

}

.box:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, .2);
}

</style>